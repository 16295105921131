import { Container } from '@troon/ui';
import { ErrorBoundary } from '../../components/error-boundary';
import type { RouteSectionProps } from '@solidjs/router';

export default function AuthPages(props: RouteSectionProps) {
	return (
		<div class="relative flex h-screen w-screen items-center justify-center bg-gradient-to-r from-neutral-950 to-brand-700 p-4 text-white">
			<img src="/topo.svg" alt="" class="absolute inset-0 z-0 h-screen w-screen object-cover" />
			<Container
				size="xsmall"
				class="z-10 items-center rounded border border-neutral bg-white p-4 text-neutral-900 md:p-12"
			>
				<ErrorBoundary contained>{props.children}</ErrorBoundary>
			</Container>
		</div>
	);
}
